<template>
    <div>
        <b-card no-body class="p-1 mb-2">
            <div class="row">
                <b-col cols="12" class="text-right">
                    <b-button v-b-toggle.settings @click="onSettingsToggle" variant="outline-dark" class="mr-1">
                        <b-icon-gear-fill/>
                        Настройки
                    </b-button>
                    <b-button variant="primary" @click="load" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                </b-col>
            </div>
            <b-collapse id="filters">
                <div class="card-body">
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Склад">
                                <storage-picker v-model="storage"></storage-picker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>

        <b-collapse id="settings" class="mt-2">
            <b-card class="settings-panel">
                <task-move-settings
                    context="schedule"
                    v-model="moveSettings"
                    :show-can-move="true"
                />
            </b-card>
        </b-collapse>

        <kendo-scheduler v-if="showScheduler"
            :data-source="dataSource"
            :date="date"
            @edit="onEdit"
            :major-time-header-template="timeTemplate"
            :footer="false"
            :event-template="eventTemplate"
            @navigate="onNavigate"
            id="taskScheduler"
            :snap="false"
            :editable-move="moveSettings.canMove"
            :editable-resize="moveSettings.canMove"
            @moveend="onDragEnd"
            @resizeend="onDragEnd"
        >
            <kendo-scheduler-view type="day"></kendo-scheduler-view>
        </kendo-scheduler>
        <task-edit :task-to-change="selectedTask" @save="onSave"></task-edit>
    </div>
</template>

<script>
import moment from 'moment'
import {Scheduler} from '@progress/kendo-scheduler-vue-wrapper'
import StoragePicker from '@components/_common/StoragePicker'
import TaskEdit from './components/TaskEdit';
import TaskMoveSettings from './components/TaskMoveSettings';
import {PRODUCTION_TASKS_ENDPOINT} from '@utils/endpoints'
import {debounce} from 'lodash'

const WITH = [
    'operation',
    'nomenclature',
    'nomenclature.measureUnit',
    'plan',
    'plan.nomenclature'
]

export default {
    components: {
        TaskEdit,
        StoragePicker,
        Scheduler,
        TaskMoveSettings,
    },
    watch: {
        date() {
            this.load()
        },
        storage() {
            localStorage.setItem('production-task-schedule-storage', JSON.stringify(this.storage))
            this.load()
        },
        'moveSettings.canMove'() {
            // Для того чтобы применились настройки
            this.showScheduler = false
            this.$nextTick(() => {
                this.showScheduler = true
            })
        }
    },
    data() {
        return {
            showScheduler: true,
            selectedTask: null,
            settingsOpen: false,
            moveSettings: {
                fixedSpaces: false,
                canMoveRightTasks: false,
                canMoveLeftTasks: true,
                canMoveAlreadyStarted: false,
                canOverlapDeadline: false,
                canMove: false
            },
            date: new Date,
            storage: null,
            dataSource: null,
            eventTemplate: '<div style="font-size: 11px; overflow: auto; width:100%;height:100%;background-color: #: backgroundColor #">\n    #: operation #<br>\n    #: nomenclature #<br>\n    #: count # #: measureUnit #\n    <hr style="margin: 2px 0">\n    #: productionNomenclature #\n</div>'
        }
    },
    methods: {
        onDragEnd(event) {
            event.context = this
            this.onDragEndDebounced(event)
        },
        onDragEndDebounced: debounce((event) => {
            event.context.updateTask({
                id: event.event.id,
                end: event.end,
                start: event.start,
            })
        }, 500),
        onSettingsToggle() {
            this.settingsOpen = !this.settingsOpen
        },
        onEdit(event) {
            event.preventDefault()
            this.$http
                .get(PRODUCTION_TASKS_ENDPOINT + '/' + event.event.id, {
                    params: {
                        with: WITH
                    }
                })
                .then(response => response.data.data)
                .then(task => {
                    this.selectedTask = {
                        ...task,
                        popoverDescription: !!task.nomenclature ? (task.nomenclature.name + ' ' + task.count + ' ' + task.nomenclature.measureUnit.name) : task.count.toString(),
                        popoverDetails: task.description,
                        title: task.operation.name,
                        end: event.event.end,
                        start: event.event.start,
                    }
                })
        },
        onSave(task) {
            this.updateTask(task)
        },
        updateTask(task) {
            let taskEdited = {
                id: task.id,
                isProdPlan: task.isProductionPlan ? 1 : 0,
                end: moment(task.end).format('YYYY-MM-DD HH:mm'),
                start: moment(task.start).format('YYYY-MM-DD HH:mm'),
                moveSettings: this.moveSettings,
                changeStart: task.changeStart
            }
            this.$http
                .post(PRODUCTION_TASKS_ENDPOINT + '/move', taskEdited)
                .then(() => {
                    this.load();
                })
                .catch(error => {
                    if (!!error.data && !!error.data.message) {
                        this.$bvToast.toast(error.data.message, {variant: 'danger'});
                        this.load();
                        return;
                    }

                    throw error
                })
        },
        onNavigate({date}) {
            this.date = date
        },
        timeTemplate({date}) {
            return moment(date).format('HH:mm')
        },
        load() {
            if (!this.date || !this.storage) {
                return
            }

            this.$http.get(PRODUCTION_TASKS_ENDPOINT, {
                params: {
                    filters: {
                        storage_id: this.storage.id,
                        date: moment(this.date).format('YYYY-MM-DD')
                    },
                    with: WITH,
                    limit: 10000
                }
            })
                .then(response => response.data.data.data)
                .then(data => {
                    this.dataSource = data.map(task => ({
                        id: task.id,
                        start: task.production_start_at,
                        end: task.production_end_at,
                        operation: task.operation.name,
                        nomenclature: !!task.nomenclature ? task.nomenclature.name : '',
                        measureUnit: !!task.nomenclature ? task.nomenclature.measureUnit.name : '',
                        count: task.count,
                        backgroundColor: task.operation.color,
                        productionNomenclature: task.plan.nomenclature.name
                    }))
                })
        }
    },
    mounted() {
        let storageJson = localStorage.getItem('production-task-schedule-storage')

        if (storageJson) {
            this.storage = JSON.parse(storageJson)
        }

        this.load()
    },
    beforeCreate() {
        document.body.className = 'body-fix-height';
    }
}
</script>
<style>
.schedule-container, #taskScheduler {
    height: 100% !important;
}
</style>
