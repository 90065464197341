<template>
    <b-modal ref="modal" :title="task.popoverDescription">
        <h3 v-bind:style="colorStyle"> {{ task.title }} </h3>
        <div class="row">
            <div class="col-md-6">
                <b-form-group label="Начало">
                    <date-picker
                            v-model="task.start"
                            format="HH:mm"
                            :type="pickerType"
                            :clearable="false"
                            :confirm="true"
                            :editable="false"
                            lang="ru"
                            :minute-step="10"
                            ref="startPicker"
                            @change="processIntervalChange(task.start, task.end, 'end')"
                            @confirm="restorePickerType"
                    >
                    </date-picker>
                    <button type="button" @click="openStartCalendar()" class="btn btn-link">
                        {{ formatToDateString(task.start) }}
                    </button>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group label="Конец">
                    <date-picker
                            v-model="task.end"
                            format="HH:mm"
                            :type="pickerType"
                            :clearable="false"
                            :confirm="true"
                            :editable="false"
                            lang="ru"
                            :minute-step="10"
                            ref="endPicker"
                            @change="processIntervalChange(task.start, task.end, 'start')"
                            @confirm="restorePickerType"
                    >
                    </date-picker>
                    <button type="button" @click="openEndCalendar()" class="btn btn-link">
                        {{ formatToDateString(task.end) }}
                    </button>
                </b-form-group>
            </div>
        </div>
        <div class="row" v-if="!isFixedDuration">
            <div class="col-md-6">
                <b-form-group :description="durationDescription" label="Продолжительность">
                    <date-picker
                            v-model="task.duration"
                            format="HH:mm"
                            type="time"
                            :clearable="false"
                            :confirm="true"
                            lang="ru"
                            :minute-step="10"
                            @change="processDurationChange(task.duration, task)"
                    >
                    </date-picker>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group label="Изменять">
                    <b-form-checkbox
                        v-model="changeStart"
                        switch
                        size="lg"
                    >
                        {{ changeStart ? ' Начало' : ' Конец' }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
        </div>

        <p>
            {{ task.popoverDetails }}
        </p>

        <template #modal-footer>
            <div class="text-center">
                <button type="button" class="btn btn-primary btn-sm" @click="save">
                    Сохранить
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import moment from 'moment'

    import DatePicker from 'vue2-datepicker'

    export default {
        name: "TaskEdit",
        props: {
            taskToChange: {
                type: Object,
                default: null
            },
            isFixedDuration: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            DatePicker
        },
        computed: {
            durationDescription() {
                return this.changeStart ? 'Будет изменяться начало задания' : 'Будет изменяться конец задания';
            }
        },
        watch: {
            taskToChange(task) {
                let duration = new Date(task.end.getTime() - task.start.getTime());
                duration.setHours(duration.getUTCHours());
                if (task) {
                    this.task = {
                        ...task,
                        start: task.start,
                        end: task.end,
                        duration: duration,
                    };
                    this.colorStyle.color = task.backgroundColor;
                    this.$refs.modal.show()
                } else {
                    this.$refs.modal.hide()
                }
            },
        },
        data() {
            return {
                task: {
                    start: null,
                    end: null,
                    duration: null,
                    popoverDescription: '',
                    popoverDetails: '',
                    title: ''
                },
                changeStart: true,
                colorStyle: {
                    color: '#212121'
                },
                pickerType: 'time'
            }
        },
        methods: {
            formatToDateString(date) {
                return moment(date).format('DD.MM.YYYY');
            },
            openEndCalendar() {
                this.pickerType = 'datetime';
                this.$refs.endPicker.$forceUpdate();
                this.$refs.endPicker.showPopup();
            },
            openStartCalendar() {
                this.pickerType = 'datetime';
                this.$refs.endPicker.$forceUpdate();
                this.$refs.startPicker.showPopup();
            },
            restorePickerType() {
                this.pickerType = 'time';
            },
            processDurationChange(duration, task) {
                if (!duration) {
                    return
                }

                if (this.changeStart) {
                    task.start = this.subInterval(task.end, duration);
                } else {
                    task.end = this.addInterval(task.start, duration);
                }
            },
            processIntervalChange(start, end, toShift) {
                if (this.isFixedDuration) {
                    this.task[toShift] = toShift === 'end'
                        ? this.addInterval(start, this.task.duration)
                        : this.subInterval(end, this.task.duration);
                } else {
                    let duration = new Date(end.getTime() - start.getTime());
                    this.task.duration = duration.setHours(duration.getUTCHours());
                }
            },
            addInterval(date, interval, subtract = false) {
                let intervalValue = ((interval.getHours() * 3600) + (interval.getMinutes() * 60)) * 1000;
                return new Date(subtract ? date.getTime() - intervalValue : date.getTime() + intervalValue);
            },
            subInterval(date, interval) {
                return this.addInterval(date, interval, true);
            },
            save() {
                this.$emit('save', {
                    ...this.taskToChange,
                    start: this.task.start,
                    end: this.task.end,
                    duration: this.task.duration,
                })
                this.$refs.modal.hide()
            }
        }
    }
</script>
