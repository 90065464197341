<template>
    <b-form-group label="Настройки" class="settings-checkbox-group">
        <div class="row">
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.canMoveRightTasks">
            <label>Правые задания сдвигаемы</label>
        </div>
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.canMoveLeftTasks">
            <label>Левые задания сдвигаемы</label>
        </div>
        <br>
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.canMoveAlreadyStarted">
            <label>Можно двигать начатые</label>
        </div>
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.fixedSpaces">
            <label>Фиксированные промежутки</label>
        </div>
        <br>
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.canOverlapDeadline">
            <label>Можно выходить за дедлайн</label>
        </div>
        <div class="col-sm-6">
            <input type="checkbox" v-model="moveSettings.updateForcibly">
            <label>Обновлять принудительно</label>
        </div>
        <template v-if="showCanMove">
            <div class="col-sm-6">
                <input type="checkbox" v-model="moveSettings.canMove">
                <label>Разрешено перетаскивание</label>
            </div>
        </template>
        </div>
    </b-form-group>
</template>

<script>
    export default {
        name: "TaskMoveSettings",
        props: {
            context: {
                type: String,
                default: 'general'
            },
            showCanMove: {
                type: Boolean,
                default: false
            },
            showUpdateForcibly: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            moveSettings: {
                handler(value) {
                    this.saveChangedSettingsValue(JSON.stringify(value), this.getPath())
                    this.$emit('input', this.moveSettings)
                },
                deep: true
            }
        },
        mounted() {
            this.restore()
        },
        methods: {
            getPath() {
                return this.context + '.moveSettings'
            },
            restore() {
                let settings = window.localStorage.getItem(this.getPath())
                if (settings) {
                    this.moveSettings = JSON.parse(settings)
                }
            },
            saveChangedSettingsValue(value, key) {
                if (value !== null) {
                    window.localStorage.setItem(key, value)
                } else {
                    window.localStorage.removeItem(key)
                }
            },
        },
        data() {
            return {
                moveSettings: {
                    fixedSpaces: false,
                    canMoveRightTasks: false,
                    canMoveLeftTasks: true,
                    canMoveAlreadyStarted: false,
                    canOverlapDeadline: false,
                    canMove: false,
                    updateForcibly: false
                },
            }
        }
    }
</script>